<script setup lang="ts">
import type { PageBodyElementRichText } from '~~/types/api/page/PageBodyElement'

interface Props {
  id: string
  content: PageBodyElementRichText['value']
}

const props = defineProps<Props>()
</script>

<template>
  <div>
    <!-- eslint-disable vue/no-v-html -->
    <div class="prose lg:prose-xl mx-auto px-4" v-html="props.content.html" />
  </div>
</template>
