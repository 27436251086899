<script setup lang="ts">
import type { PageBodyElementUnorderedList } from '~~/types/api/page/PageBodyElement'

interface Props {
  id: string
  content: PageBodyElementUnorderedList['value']
}

const props = defineProps<Props>()
</script>

<template>
  <ul class="prose lg:prose-xl mx-auto space-y-4 px-4">
    <li
      v-for="item in props.content.items"
      :key="`${id}_${item.id}`"
      class="flex flex-row space-x-4 !pl-0"
    >
      <YscIconsArrowRightLong class="mt-1 h-8 w-8 shrink-0 text-pink-500" />
      <span>{{ item.value }}</span>
    </li>
  </ul>
</template>
