<script setup lang="ts">
import type { PageBodyElementRichTextHighlighted } from '~~/types/api/page/PageBodyElement'

interface Props {
  id: string
  content?: PageBodyElementRichTextHighlighted['value']
}

const props = defineProps<Props>()
</script>

<template>
  <div class="px-4">
    <!-- eslint-disable vue/no-v-html -->
    <div
      class="prose lg:prose-xl mx-auto rounded border bg-gray-100 p-8"
      v-html="props.content?.html ?? ''"
    />
  </div>
</template>

<style scoped>
.prose :deep(a) {
  @apply inline
}
</style>
