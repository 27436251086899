<script setup lang="ts">
import { GALLERY_IMAGE } from '@/helpers/types'
import type { PageBodyElementGallery } from '~~/types/api/page/PageBodyElement'

interface Props {
  id: string
  content: PageBodyElementGallery['value']
}

const props = withDefaults(defineProps<Props>(), {
  content: () => ({
    id: 'default',
    images: [],
  }),
})

const images = computed(() => props.content.images.filter(i => i.type === GALLERY_IMAGE))
const hasMultipleImages = computed(() => images.value.length > 1)
</script>

<template>
  <div
    class="mx-auto flex space-x-4 px-4"
    :class="hasMultipleImages ? 'max-w-screen-xl' : 'max-w-screen-lg'"
  >
    <figure
      v-for="image in images"
      :key="`${id}_${image.id}`"
      class="w-full space-y-1.5"
    >
      <twic-img
        v-if="image.value.image_rendered.url_relative"
        :src="useTwicPicsBlogUrl(image.value.image_rendered.url_relative)"
        class="rounded-sm will-change-transform"
        focus="auto"
        :ratio="hasMultipleImages ? '4/3' : '16/9'"
        :alt="image.value.alt"
      />
      <figcaption class="px-1 text-sm italic text-gray-500">
        {{ image.value.caption }}
      </figcaption>
    </figure>
  </div>
</template>
