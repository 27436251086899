<script setup lang="ts">
import type { PageBodyElementIframe } from '~/types/api/page/PageBodyElement'

interface Props {
  id: string
  content?: PageBodyElementIframe['value']
}

const props = defineProps<Props>()
</script>

<template>
  <div class="mx-auto prose px-4 lg:prose-xl">
    <iframe
      :src="props.content?.src"
      class="aspect-video w-full"
      loading="lazy"
    />
  </div>
</template>
