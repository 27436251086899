<script setup lang="ts">
import type { PageBodyElementTypes } from '~~/types/api/page/PageBodyElement'

interface Props {
  type: PageBodyElementTypes
  content: any
  reverse?: boolean
  id: string
}

const props = withDefaults(defineProps<Props>(), { reverse: false })

const componentName = computed(() => {
  const authorizedType: PageBodyElementTypes[] = [
    'Gallery',
    'Iframe',
    'OrderedList',
    'RichText',
    'RichTextHighlighted',
    'Separator',
    'UnorderedList',
    'CategorySection',
    'TagSection',
    'VehiclesListing',
    'VehiclesSearch',
    'Hyperlink'
  ]
  if (!authorizedType.includes(props.type)) {
    console.warn(`unknown component "${props.type}"`)
    return 'div'
  }
  return {
    Gallery: resolveComponent('AppPostBlockGallery'),
    Iframe: resolveComponent('AppPostBlockIframe'),
    OrderedList: resolveComponent('AppPostBlockOrderedList'),
    RichText: resolveComponent('AppPostBlockRichText'),
    RichTextHighlighted: resolveComponent('AppPostBlockRichTextHighlighted'),
    Separator: resolveComponent('AppPostBlockSeparator'),
    UnorderedList: resolveComponent('AppPostBlockUnorderedList'),
    CategorySection: resolveComponent('AppPostBlockCategorySection'),
    TagSection: resolveComponent('AppPostBlockTagSection'),
    VehiclesListing: resolveComponent('AppPostBlockVehiclesListing'),
    VehiclesSearch: resolveComponent('AppPostBlockVehiclesSearch'),
    Hyperlink: resolveComponent('AppPostBlockHyperlink')
  }[props.type] ?? 'div'
})
</script>

<template>
  <component
    :is="componentName"
    :id="props.id"
    :content="props.content"
    :reverse="props.reverse"
  />
</template>
