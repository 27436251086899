<script setup lang="ts">
import type { PageBodyElementOrderedList } from '~~/types/api/page/PageBodyElement'

interface Props {
  id: string
  content: PageBodyElementOrderedList['value']
}

const props = defineProps<Props>()
</script>

<template>
  <ol class="prose lg:prose-xl mx-auto space-y-4 px-4">
    <li
      v-for="(item, key) in props.content.items"
      :key="`${id}_${item.id}`"
      class="flex flex-row space-x-4 !pl-0"
    >
      <div
        class="mt-1 flex h-6 w-6 items-center justify-center rounded-full bg-blue-500 p-3 text-base font-semibold lining-nums tabular-nums text-white"
      >
        {{ key + 1 }}
      </div>
      <span>{{ item.value }}</span>
    </li>
  </ol>
</template>
